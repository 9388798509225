import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/jonespen/projects/urbaninfrastructure/react-ui-kit/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "urban-sharing-design"
    }}>{`Urban Sharing Design`}</h1>
    <p>{`Design system for `}<a parentName="p" {...{
        "href": "https://urbansharing.com"
      }}>{`Urban Sharing`}</a>{`, used to build internal and public products.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/docs-design-tokens"
        }}>{`Colors`}</a>{` with color codes for screen and print`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/docs-icons"
        }}>{`Icons`}</a>{` used in the products`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/docs-ui-kit"
        }}>{`Typography`}</a>{` rules and our spacing system`}</li>
    </ul>
    <p>{`Please see the `}<a parentName="p" {...{
        "href": "/docs-setup"
      }}>{`Technical setup`}</a>{` for how to install and use the design system in a new project.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      